<template>
  <div class="itemShow">
    <div class="banner">
      <div class="title">
        <div>工会工作向数字化转型</div>
        <div style="transform:translateX(25%)">科技驱动工作模式革新</div>
        <div style="transform:translateX(50%)">让工会工作变得更智慧</div>
      </div>
    </div>
    <div class="itemShow-main">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#fff"
        text-color="#000"
        active-text-color="#f76151"
        router
      >
        <el-menu-item index="/itemShow?categoryId=2">全部案例</el-menu-item>
        <el-menu-item index="/itemShow?categoryId=21">高校案例</el-menu-item>
        <el-menu-item index="/itemShow?categoryId=22">政府案例</el-menu-item>
        <el-menu-item index="/itemShow?categoryId=23">企业案例</el-menu-item>
      </el-menu>
      <div class="itemShow-content">
        <router-view></router-view>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "@/components/footer/footer.vue";
export default {
  name: "item",
  components: { Foot },
  data() {
    return {};
  },
  computed: {
    activeIndex() {
      if (!this.$route.query.categoryId) {
        this.$router.push({
          path: "/itemShow",
          query: {
            categoryId: 2,
          },
        });
        return "/itemShow?categoryId=2";
      }
      return `/itemShow?categoryId=${this.$route.query.categoryId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
// .itemShow {
//   .banner {
//     height: 380px;
//     width: 100%;
//     background-image: url(~@/assets/images/itemShow/banner.png);
//     background-size: 100% 380px;
//     position: relative;
//     background-repeat: no-repeat;

//     .title {
//       font-size: 28px;
//       font-weight: 500;
//       color: #fff;
//       position: absolute;
//       left: 8%;
//       top: 50%;
//       transform: translateY(-50%);
//       padding-bottom: 10px;
//       div {
//         padding-bottom: 15px;
//       }
//     }
//   }


// .main {
//   .el-menu {
//     padding-left: 8.5%;
//   }

//   .content {
//     background: url(~@/assets/images/news/bg.png);
//     padding-top: 30px;
//     width: 100%;
//     background-position: center;
//     background-size: 100%;
//     background-repeat: no-repeat;

//     .wrapper {
//       display: grid;
//       padding: 0 8%;
//       grid-template-columns: 28% 28% 28%;
//       grid-row-gap: 50px;
//       justify-content: space-between;
//     }

//     .box {
//       background-color: #fff;
//       max-width: 450px;
//       width: 115%;
//       position: relative;
//       border: 1px solid #ccc;

//       .el-divider {
//         margin: 13px 0;
//       }

//       .bottom {
//         padding: 10px 20px 20px 20px;
//         .summary {
//           line-height: 20px;
//           height: 40px;
//           overflow: hidden;
//           display: -webkit-box;
//           -webkit-box-orient: vertical;
//           -webkit-line-clamp: 2;
//           // widows: 100%;
//         }
//       }

//       .date {
//         color: #fff;
//         position: absolute;
//         text-align: center;
//         top: 0;
//         left: 27px;
//         z-index: 99;
//         width: 80px;
//         height: 90px;
//         border-radius: 0 0 20px 20px;
//         background-color: #fe7f72;

//         .day {
//           font-size: 35px;
//           font-weight: bold;
//           padding-bottom: 8px;
//           padding-top: 10px;
//         }

//         .month {
//           font-size: 14px;
//         }
//       }
//     }
//   }

//   .pagination {
//     display: flex;
//     justify-content: center;
//     width: 100%;
//     padding-bottom: 100px;
//     padding-top: 20px;
//     :deep(.el-pager) {
//       li.active {
//         background-color: #fe7f72;
//       }
//     }
//   }
// }
// }
</style>
